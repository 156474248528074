import React from "react";
import "./css/Footer.css";
import { FaLinkedinIn, FaInstagram, FaTiktok } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="footer-container d-flex flex-column align-items-center justify-content-center pt-3 pb-4">
      <div className="social-media-icons d-flex justify-content-center">
        <a href="https://www.instagram.com/belle.designstudio/" target="_blank" rel="noopener noreferrer" className="social-icon"><FaInstagram/></a>
        <a href="https://www.linkedin.com/company/belle-design-studio" target="_blank" rel="noopener noreferrer" className="social-icon"><FaLinkedinIn/></a>
        <a href="https://www.tiktok.com/@belle.designstudio" target="_blank" rel="noopener noreferrer" className="social-icon"><FaTiktok/></a>
      </div>

      {/* <div className="footer-links d-flex justify-content-center md-2">
        <a href="/terms" className="footer-link">
          Terms & Conditions
        </a>
        <span className="md-2">|</span>
        <a href="/privacy" className="footer-link">
          Privacy Policy
        </a>
      </div> */}

      <div className="copyright md-2">&copy; 2024 Belle Design Studio</div>
    </footer>
  );
};

export default Footer;
