import React, { useRef, useState } from 'react';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../components/css/ContactForm.css';
import emailjs from '@emailjs/browser';
import ContactUs from './ContactUs';

const YOUR_SERVICE_ID = 'service_eg0dub2';
const YOUR_TEMPLATE_ID = 'template_dig6yfg';
const YOUR_USER_ID = 'sYTBvFdvXdiO0w8f0';

const GetInTouch = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const form = useRef();

  const handleCloseAlert = () => setShowAlert(false);

  const sendForm = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, form.current, YOUR_USER_ID)
      .then(
        (result) => {
          setAlertMessage('Email sent successfully!');
          setShowAlert(true);
        },
        (error) => {
          setAlertMessage('Failed to send email. Please try again.');
          setShowAlert(true);
        }
      );
  };

  return (
    <Container className="contact-container pt-5">
      <Row>
        <Col xs={6} className="contact-info">
          <h2>Contact Us</h2>
          <p><FontAwesomeIcon icon="fa-solid fa-phone"/> +62 819-0979-0555</p>
          <p><FontAwesomeIcon icon="fa-solid fa-envelope"/> contact@belledesignstudio.org</p>
          <p><FontAwesomeIcon icon="fa-solid fa-location-dot"/> Apartemen Royal Mediterania, Tanjung Duren, Kecamatan Grogol, Pertamburan, Jakarta Barat, Indonesia - 11470</p>
          <iframe
            title="location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31736.734944800175!2d106.7826164!3d-6.1753875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f70a5c3624ed%3A0x40113fe9d62a480!2sApartemen%20Royal%20Mediterania%20Garden%20Residences!5e0!3m2!1sen!2sid!4v1589452384566!5m2!1sen!2sid"
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
        </Col>

        <Col xs={6} className="contact-form">
          <Form className="_formControl" ref={form} onSubmit={sendForm}>
            <h3 className="text-center">Get in Touch with Us!</h3>
            <Form.Group controlId="formName">
              <Form.Control
                type="text" 
                placeholder="Your Name..." 
                name="from_name"
                className="form-control" 
                required
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Control
                type="email" 
                placeholder="Your Email..." 
                name="from_email"
                className="form-control"
                required
              />
            </Form.Group>
            <Form.Group controlId="formPhone">
              <Form.Control
                type="text" 
                placeholder="Your Phone..."
                name="from_phone"
                className="form-control" 
              />
            </Form.Group>
            <Form.Group controlId="formSubject">
              <Form.Control
                type="text" 
                placeholder="Your Subject..." 
                name="from_subject"
                className="form-control"
                required
              />
            </Form.Group>

            <Form.Group controlId="formMessage">
              <Form.Control 
                as="textarea" 
                rows={3} 
                placeholder="Your Message..." 
                name="message"
                className="form-control"
                required
              />
            </Form.Group>

            <div className="d-flex justify-content-center">
              <Button variant="primary" type="submit">Submit</Button>
            </div>
          </Form>
        </Col>
      </Row>

      <Modal show={showAlert} onHide={handleCloseAlert}>
        <Modal.Header closeButton>
          <Modal.Title>{alertMessage.includes('successfully') ? 'Success' : 'Error'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{alertMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAlert}>Close</Button>
        </Modal.Footer>
      </Modal>

      <ContactUs />
      
    </Container>
  );
};

export default GetInTouch;